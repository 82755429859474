<template>
  <a-affix>
    <header class="wrap">
      <a-tooltip titme="123123">
        <div class="left">
          <LogoMenu/>
        </div>
      </a-tooltip>

      <div class="right">
        <div class="phones">
          <span>8-951-237-9748</span>
          <span class="delimiter">/</span>
          <span>8-909-092-0182</span>
        </div>

        <a href="/rooms"><button>Найти номер</button></a>

        <div class="mobile-menu">
          <MenuOutlined @click="() => showMobileMenu = true" />

          <div class="fixed-mobile-menu" v-show="showMobileMenu">
            <a-button @click="() => showMobileMenu = false">
              <CloseOutlined />
            </a-button>

            <logo-menu/>

            <div class="phones">
              <span>8-951-237-9748</span>
              <span class="delimiter">/</span>
              <span>8-909-092-0182</span>
            </div>

            <a class="button" href="/rooms"><button>Найти номер</button></a>
          </div>
        </div>
      </div>
    </header>
  </a-affix>

  <main>
    <div class="wrap content">
      <router-view/>
    </div>

    <footer class="wrap contacts-wrap">
      <h2>Как добраться?</h2>

      <div class="map">
        <div class="text">
          <div>
            <h5>Адрес:</h5>
            <p>
              Башкортостан Белорецкий район с. Новоабзаково ул. Луговая д.5.<br>
              (Поворот направо через 100м после моста через р.Малый Кизил)
            </p>
          </div>

          <div>
            <h5>Телефоны:</h5>
            <p>
              Администратор дома для гостей “Каменный цветок”<br>
              8-951-237-9748<br>
              8-909-092-0182
            </p>
          </div>

          <div>
            <h5>Координаты для навигатора:</h5>
            <p>
              53.813305<br>
              58.634889
            </p>
          </div>

          <div class="icons">
            <a href="https://www.instagram.com/kamcvet_house/?utm_source=qr&r=nametag" target="_blank"><InsIcon :color="'#006538'"/></a>
            <a href="https://t.me/kamcvet_house" target="_blank"><TelIcon :color="'#006538'"/></a>
            <a href="https://wa.me/+79512379748" target="_blank"><WatIcon :color="'#006538'"/></a>
            <a href="viber://chat?number=+79512379748" target="_blank"><ViberIcon :color="'#006538'"/></a>
          </div>

          <div class="copyright">
            © Copyright 2012 - {{ new Date().getFullYear() }}г.
          </div>
        </div>

        <div style="position:relative;overflow:hidden;">
          <div style="position:relative;overflow:hidden;">
            <iframe
                src="https://yandex.ru/map-widget/v1/?ll=58.634867%2C53.813242&mode=whatshere&whatshere%5Bpoint%5D=58.634764%2C53.813275&whatshere%5Bzoom%5D=17&z=19.2"
                frameborder="0" allowfullscreen="true" style="position:relative;"></iframe>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <LogoMenu/>

        <a href="/rooms"><button>Найти номер</button></a>
      </div>
    </footer>
  </main>
</template>

<script>
import LogoMenu from "@/components/LogoMenu";
import VkIcon from "@/components/icons/WatIcon";
import InsIcon from "@/components/icons/InsIcon";
import TelIcon from "@/components/icons/TelIcon";
import WatIcon from "@/components/icons/WatIcon";
import MenuComponent from "@/components/MenuComponent";
import { MenuOutlined, CloseOutlined } from '@ant-design/icons-vue';
import ViberIcon from "@/components/icons/ViberIcon";

let intervalScroll = '';

export default {
  name: 'AppLayout',
  components: { ViberIcon, MenuComponent, WatIcon, TelIcon, InsIcon, VkIcon, LogoMenu, MenuOutlined, CloseOutlined },
  data: function () {
    return {
      firstScroll: true,
      showMobileMenu: false
    }
  },

  watch: {
    '$route': function () {
      this.showMobileMenu = false;
      this.scroll()
    }
  },

  created() {
    this.scroll();
  },

  methods: {
    scroll() {
      const route = this.$route
      const path = route.path.split('/');
      const activeTab = path[path.length - 1];

      if (path.length > 2 && !!activeTab) {
        let counter = 15;

        if (intervalScroll) {
          clearInterval(intervalScroll);
        }

        intervalScroll = setInterval(() => {
          counter--;

          if (counter == 0) {
            clearInterval(intervalScroll);
          }

          const element = document.querySelectorAll(`.${ activeTab }-wrap`);

          if (element[0]) {
            clearInterval(intervalScroll);

            setTimeout(() => {
              const y = element[0].getBoundingClientRect().top + window.scrollY;

              window.scroll({
                top: y,
                behavior: 'smooth'
              });

              this.firstScroll = false;
            }, this.firstScroll ? 500 : 0);
          }
        }, 100);
      }
    },
  }
}
</script>

<style lang="less">
@import "assets/style";
</style>
