<template>
  <div class="banner">
    <img src="../assets/Abzakovo,_panorama1.jpg">

    <div class="text">
      <h3>Абзаково</h3>
    </div>
  </div>

  <div class="text-content">
    <p>Село Абзаково расположено на реке Кульсагады (приток реки Малый Кизил), в 20 км к Юго-Востоку от райцентра и 5 км
      к
      cеверо-западу от ж.д. станции Новоабзаково. Окружено горами Бикембет и Халмаурды, хребет Кыркты.</p>

    <p>Основано, приблизительно, в 1745 году башкирами Кубелякской волости Ногайской дороги на собственных землях.
      Названо
      по имени волостного старшины Абзака Баимова. Прежнее название местности — Тубяк.</p>

    <p>В настоящее время на территории села расположены школа, амбулатория, Дворец Культуры, библиотека, мечеть,
      спортивно-оздоровительный комплекс «Абзаково».</p>

    <p>ГЛЦ «Абзаково» — оздоровительно-спортивный комплекс ООО «Абзаково» расположен на территории республики
      Башкортостан, в 60 километрах от г. Магнитогорска, 35 километрах от г. Белорецка.</p>

    <p>Абзаково — популярный на Урале центр горнолыжного спорта, сноубординга, велосипедного и мотоциклетного спорта.
      Горнолыжный сезон длится с ноября по май. Имеется 15 трасс общей протяжённостью 18 километров при перепаде высот
      до
      320 метров. Склоны обслуживают пять современных бугельных подъёмников суммарной пропускной способностью 5000
      человек.</p>

    <p>На территории Абзаково расположены:</p>

    <ul>
      <li>Аквапарк «Аквариум»</li>
      <li>Зоопарк</li>
      <li>Ночной клуб, развлекательный комплекс</li>
      <li>Отели, магазины, рестораны</li>
      <li>Медико-оздоровительный отдел</li>
    </ul>

    <p>«Абзаково» организует сплавы по рекам Башкирии — Белая, Зилиму, Инзеру. В летнюю экскурсионную программу в
      «Абзаково» входят пешие, велосипедные, конные маршруты. Присутствуют обзорные вертолетные экскурсии. Летом активны
      пляжи, зимой — каток.</p>

    <p>Курорт «Абзаково» долгие годы считается лучшим на Южном Урале. В июне 2011 года горнолыжный курорт «Абзаково»
      признан лучшим курортом для семейного и детского отдыха. Высокую оценку он получил в номинации Премии Союза
      горнолыжной индустрии — 2011 в ходе первого объединенного конгресса по зимним видам спорта, который проходил с 15
      по
      17 июня 2011 года на горнолыжном комплексе Игора в Ленинградской области в дни проведения Санкт-Петербургского
      экономического форума.</p>
  </div>
</template>

<script>
export default {
  name: 'ResortView',
}
</script>