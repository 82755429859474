<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_87)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.5965 19.4035 0 12.5 0C5.5965 0 0 5.5965 0 12.5C0 19.4035 5.5965 25 12.5 25ZM17.9942 7.986C18.0907 6.891 16.9342 7.342 16.9342 7.342C16.0805 7.6955 15.2 8.0545 14.31 8.4175C11.55 9.54325 8.699 10.7063 6.27025 11.851C4.95325 12.334 5.72425 12.817 5.72425 12.817L7.812 13.4613C8.77575 13.7513 9.2895 13.429 9.2895 13.429L13.7865 10.3695C15.3925 9.2745 15.007 10.1762 14.6215 10.5627L11.249 13.7832C10.735 14.2343 10.992 14.6208 11.2168 14.814C11.8542 15.3763 13.4218 16.405 14.106 16.854C14.284 16.9708 14.4023 17.0485 14.429 17.0685C14.5895 17.1973 15.4567 17.777 16.035 17.6483C16.6133 17.5193 16.6775 16.7785 16.6775 16.7785L17.4482 11.722C17.5637 10.8442 17.6965 10.0005 17.8025 9.3265C17.9025 8.69 17.9788 8.20525 17.9942 7.986Z"
            :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_7_87">
        <rect width="25" height="25" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TelIcon',
  props: [ 'color' ]
}
</script>