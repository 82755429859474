<template>
  <div class="photo-gallery">
    <h2>Фотогалерея</h2>
    <div class="images">
      <a-image-preview-group>

        <a-image :height="200" src="/images/20190914_152816.jpg"/>
        <a-image :height="200" src="/images/20230930_173027.jpg"/>
        <a-image :height="200" src="/images/20231202_170158.jpg"/>
        <a-image :height="200" src="/images/20191019_115009.jpg"/>
        <a-image :height="200" src="/images/20191019_115102.jpg"/>
        <a-image :height="200" src="/images/20200830_150353.jpg"/>
        <a-image :height="200" src="/images/20200830_155500.jpg"/>
        <a-image :height="200" src="/images/20201003_160824.jpg"/>
        <a-image :height="200" src="/images/20201004_130726.jpg"/>
        <a-image :height="200" src="/images/20220529_133428.jpg"/>
        <a-image :height="200" src="/images/20220731_144159.jpg"/>
        <a-image :height="200" src="/images/20220813_130905.jpg"/>
        <a-image :height="200" src="/images/20220813_132114.jpg"/>
        <a-image :height="200" src="/images/20220924_114145.jpg"/>
        <a-image :height="200" src="/images/20220925_163058.jpg"/>
        <a-image :height="200" src="/images/20220925_163132.jpg"/>
        <a-image :height="200" src="/images/20220925_163156.jpg"/>
        <a-image :height="200" src="/images/20220925_163323.jpg"/>
        <a-image :height="200" src="/images/20230916_143713.jpg"/>
        <a-image :height="200" src="/images/20230916_144005.jpg"/>
        <a-image :height="200" src="/images/20230916_171336.jpg"/>
        <a-image :height="200" src="/images/20230930_171736.jpg"/>
        <a-image :height="200" src="/images/20230930_172209.jpg"/>
        <a-image :height="200" src="/images/20230930_172427.jpg"/>
        <a-image :height="200" src="/images/20230930_172641.jpg"/>

      </a-image-preview-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PhotoView',
}
</script>

<style lang="less">
.photo-gallery {
  padding: 0;

  h2 {
    margin-left: 20px;
  }

  .images {
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;

    .ant-image {
      width: 200px;
      overflow: hidden;
      margin: 10px;

      img {
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        width: auto;
      }
    }
  }
}
</style>