<template>
  <div class="banner">
    <img src="../assets/banner_main.jpg">

    <div class="wrap">
      <div class="text">
        <h3>Бронирование номеров</h3>
      </div>
    </div>
  </div>

  <div class="booking">
    <div id="rc-bookings-widget-root"></div>
  </div>
</template>

<script>
export default {
  name: 'RoomsView',
  created() {
    let interval = setInterval(async () => {
          const el = document.getElementById('rc-bookings-widget-root');

          if (el) {
            clearInterval(interval)

            const insertScript = document.createElement('script')
            insertScript.setAttribute('src', 'https://realtycalendar.ru/webpack/application.js?_t=96e16d8bfffff344a38a')
            await document.body.appendChild(insertScript)

            let interval2 = setInterval(async () => {
              if (!!RC_BOOKINGS_WIDGET) {
                clearInterval(interval2);

                RC_BOOKINGS_WIDGET.init('e2aa063f6dda49372822e2195d74d3ce');
              }
            }, 300);
          }
        }, 300
    );
  }
}
</script>

<style lang="less">
.booking {
  overflow: hidden;
}

.rc-container {
  width: 100%;
  margin: 0;
  padding: 0;

  .rc-search_form {
    border-radius: 20px;
  }
}
</style>