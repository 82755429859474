<template>
  <a class="logo" href="/"/>

  <menu-component/>
</template>

<script>
import MenuComponent from "@/components/MenuComponent";
export default {
  name: 'LogoMenu',
  components: { MenuComponent },
}
</script>

<style lang="less" scoped>
.logo {
  width: 229px;
  height: 38px;
  background: url('../assets/logo.png');
}
</style>