<template>
  <div class="banner">
    <img src="../assets/banner_main.jpg">

    <div class="wrap">
      <div class="text">
        <p>Добро пожаловать в<br>дом для гостей</p>
        <img src="/images/logo_banner.png"/>
        <br>
        <a href="/rooms">
          <button class="light">ЗАБРОНИРОВАТЬ</button>
        </a>
      </div>
    </div>
  </div>

  <div class="about about-wrap">
    <div class="item">
      <div class="text">
        <h2>О нас</h2>

        <p>
          Откройте для себя жилой Дом для гостей "Каменный цветок", расположенный на курорте "Абзаково" среди
          прекрасного леса и гор. Двухэтажный коттедж одним своим видом привлекает взгляд
          и обещает гостеприимство горнолыжникам и сноубордистам, заехавшим покататься на склоны "Абзаково". Кованые
          ворота открывают въезд на охраняемую стоянку и территорию дома. Войдя внутрь, попадаешь в сказку: основным
          элементом обширного холла является настоящий камин, обложенный камнем. Вся обстановка напоминает горный приют
          в швейцарских Альпах. Высокий двухсветный потолок, деревянные балки под ним, массивный кованый светильник,
          свешивающийся на цепях, лестница с узорными перилами... Встреченные радушными хозяевами гости попадают в
          уютные и комфортные комнаты для проживания, а домашняя атмосфера располагает к тихому семейному отдыху.
          Гостеприимство и ненавязчивый сервис хозяев предупреждают практически каждое желание постояльцев.
        </p>
      </div>

      <div class="img">
        <img src="../assets/Rectangle%201.jpg"/>
      </div>
    </div>

    <div class="item">
      <div class="img">
        <img src="../assets/Rectangle%202.jpg"/>
      </div>

      <div class="text">
        <p>
          Радушие, гостеприимство и ненавязчивый сервис хозяев предупреждают практически каждое желание постояльцев. В
          холле можно разместиться на удобном диване, полистать журналы, поиграть в настольные игры или у горящего
          камина устроить вечеринку с компанией друзей. Собраться теплым кругом друзей можно и в столовой комнате с
          большим ЖК-телевизором.
        </p>
        <p>
          На придомовой территории все радует глаз - ухоженные газоны, прекрасная природа с вековыми деревьями. Можно
          приготовить барбекю и перекусить на свежем воздухе в красивых беседках под склоном горы. Для маленьких гостей
          есть игровая площадка. А в тёплое время года работает открытый бассейн с подогревом с бирюзовой водой
        </p>
      </div>
    </div>

    <div class="item">
      <div class="text">
        <p>Если холл настраивает на сказочное настроение, то в комнатах все устроено по-современному: пол с подогревом,
          большие удобные кровати с массивным изголовьем с подсветкой, красивые люстры, уютный текстиль, спутниковое
          телевидение, душевые кабины в ванной комнате, холодильник, чайник, микроволновая печь. Чайная посуда,
          одноразовые шампуни и гели, пушистые банные полотенца завершают картину продуманного гостеприимства.
        </p>
        <p>
          Уставшие после активного отдыха гости могут расслабиться в настоящей русской баньке на дровах с тёплым
          бассейном и в потрясающе уютной комнате отдыха.
        </p>
      </div>

      <div class="img">
        <img style="margin-right: 20px;" src="../assets/20220529_133428.jpg"/>
        <img style="margin-right: 20px;" src="../assets/20220813_130905.jpg"/>
        <img src="../assets/20220731_144159%201.jpg"/>
      </div>
    </div>
  </div>

  <div class="booking">
    <h2>Бронирование номеров</h2>

    <div id="rc-small-bookings-widget-root"></div>
  </div>

  <div class="services services-wrap">
    <h2>Дополнительные услуги</h2>

    <div class="item">
      <div class="img">
        <img src="../assets/img20221018152118.jpg"/>
      </div>

      <div class="text">
        <h3>
          РУССКАЯ БАНЬКА<br>на дровах
          <img src="../assets/serv1.png"/>
        </h3>

        <div class="price"><b>5000 р</b> за 2 часа</div>
        <div class="price"><b>2000 р</b> за последующие часы</div>
        <div class="desc">минимальное время предывания 2 часа<br>максимум 6 человек<br>(в т.ч. дети)</div>
      </div>
    </div>

    <div class="item right">
      <div class="text">
        <h3>
          <img src="../assets/serv2.png"/>
          ФИНСКАЯ САУНА<br/>жаркая
        </h3>

        <div class="price"><b>1500 р</b> за 1 час</div>
        <div class="desc">максимум 4 человека<br>(в т.ч. дети)</div>
      </div>

      <div class="img">
        <img src="../assets/img20221018152216.jpg"/>
      </div>
    </div>

    <div class="item">
      <div class="img">
        <img src="../assets/img20210920081649.jpg"/>
      </div>

      <div class="text">
        <h3>
          ОТКРЫТЫЙ БАССЕНЙ<br>с подогревом
          <img src="../assets/serv3.png"/>
        </h3>

        <div class="desc">
          работает в летнее время<br>
          обязательно соблюдение<br>
          <a href="pool">ИНСТРУКЦИИ ПО БЕЗОПАСНОСТИ</a>
        </div>
      </div>
    </div>
  </div>

  <div class="blockquote" v-if="false">
    Тем, кто пожил в Доме "Каменный цветок", не хочется уезжать из этого волшебного места.<br>
    А уехав, они мечтают вернуться в сказочный уголок уюта и тепла.
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  created() {
    let interval = setInterval(async () => {
          const el = document.getElementById('rc-small-bookings-widget-root');

          if (el) {
            clearInterval(interval)

            const insertScript = document.createElement('script')
            insertScript.setAttribute('src', 'https://realtycalendar.ru/webpack/search.js?_t=96e16d8bfffff344a38a')
            await document.body.appendChild(insertScript)

            let interval2 = setInterval(async () => {
              if (!!RC_SMALL_BOOKINGS_WIDGET) {
                clearInterval(interval2);

                RC_SMALL_BOOKINGS_WIDGET.init('e2aa063f6dda49372822e2195d74d3ce');
              }
            }, 300);
          }
        }, 300
    );
  }
}
</script>

<style lang="less">
.rc-form-inline {
  &.rc-search_form {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    background-color: #006538;
    margin-top: 35px;
    padding: 20px !important;

    .rc-form-group, .rc-search_form__dates_wrapper {
      display: flex;
      align-items: center;
    }

    .rc-form-group {
      margin-right: 15px;
    }
  }

  label {
    margin-bottom: 0;
    margin-right: 5px;
  }

  .rc-btn {
    display: block;
    min-width: 190px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .rc-form-inline {
    &.rc-search_form {
      display: block !important;
      padding: 20px !important
    }

    .rc-form-group, .rc-search_form__dates_wrapper {
      display: block !important;
    }

    .rc-form-group {
      margin-right: 0 !important;
    }
  }
}
</style>

<style lang="less" scoped>
.booking {
  margin-top: 100px;

  h2 {
    text-align: center;
    margin-bottom: 0;
    padding: 0 20px;
  }
}

#rc-small-bookings-widget-root {
  margin-bottom: 100px;
}

.about {
  margin-top: 70px;

  h2 {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;

    .text {
      width: calc(45% - 120px);
      padding: 0 20px;

      p {
        font-size: 14px;
        line-height: 26px;
        text-align: justify;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 10px;
      width: 55%;
      min-height: 500px;
      height: 100%;

      img {
        border-radius: 10px;
      }
    }

    &:last-child {
      .img {
        justify-content: space-between;
      }
    }
  }
}

.services {
  margin-bottom: 100px;
  overflow: hidden;
  margin-left: -20px;

  h2 {
    text-align: center;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .img {
      width: 43%;
      max-height: 500px;
      display: flex;
      justify-content: end;

      img {
        width: auto;
        height: 100%;
        max-height: 500px;
      }
    }

    &.right {
      .img {
        justify-content: start;
      }

      .text {
        text-align: right;
      }

      h3 {
        justify-content: right;

        img {
          margin-left: 0;
          margin-right: 30px;
        }
      }
    }

    .text {
      width: 50%;

      h3 {
        font-size: 35px;
        margin-bottom: 30px;
        font-weight: bold;
        color: var(--green);
        display: flex;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
          margin-left: 30px;
        }
      }

      .price, .desc {
        font-size: 18px;

        b {
          color: var(--green);
          font-size: 22px;
          font-weight: bold;
        }
      }

      .price {
        margin-bottom: 10px;
      }
    }
  }
}

.blockquote {
  background: var(--green);
  border-radius: 20px;
  padding: 70px 0;
  font-size: 30px;
  color: var(--white);
  text-align: center;
}
</style>