<template>
  <div class="banner">
    <div class="text">
      <h3>Открытый БАССЕЙН с подогревом</h3>
      <h4>работает в летнее время</h4>
      <a href="/rooms"><button class="light">ЗАБРОНИРОВАТЬ</button></a>
    </div>
  </div>

  <div class="pool-instruction">
    <h3>ИНСТРУКЦИИ ПО БЕЗОПАСНОСТИ ПРИ ПОСЕЩЕНИИ БАССЕЙНА</h3>

    <h4>1) Общие требования безопасности</h4>
    <h5>К плаванию в бассейне допускаются посетители</h5>
    <ul>
      <li>прошедшие инструктаж по мерам безопасности;</li>
      <li>дети, не умеющие плавать, допускаются в бассейн только с родителями и вспомогательными средствами для
        плавания; <b>родители несут ответственность за жизнь и здоровье своего ребенка;</b></li>
      <li>дети в возрасте до 14 лет только в сопровождении взрослых;</li>
      <li>лица старше 70 лет по разрешению врача</li>
    </ul>

    <h5>Посетитель должен</h5>
    <ul>
      <li>бережно относиться к оборудованию бассейна и использовать его по назначению;</li>
      <li>иметь купальник или плавки спортивного образца, чистые сланцы, очки, полотенце, мыло;</li>
      <li>знать и выполнять настоящую инструкцию</li>
    </ul>

    <h4>2) Требования безопасности перед посещением бассейна</h4>
    <h5>Посетитель должен</h5>
    <ul>
      <li>раздеться в раздевалке;</li>
      <li>снять с себя посторонние предметы (часы, висячие сережки, и т.д.) и пройти в душевую;</li>
      <li>тщательно мыться под душем с мылом без купального костюма;</li>
      <li>после окончания помывки закрыть за собой кран;</li>
      <li>надеть на себя купальный костюи и сланцы;</li>
    </ul>

    <h5>Запрещается</h5>
    <ul>
      <li>втирать в кожу различные мази и крема;</li>
      <li>приносить в душ бассейна стеклянную тару, режущие, колющие предметы, а также жевательную резинку и другие
        пачкающие предметы;
      </li>
      <li>пользоваться бритвенными принадлежностями и средствами эпиляции;</li>
    </ul>

    <h4>3) Требования безопасности перево время посещения бассейна</h4>
    <ul>
      <li>при плавании более 1 человека держитесь правой стороны, избегайте столкновений с другими посетителями;</li>
      <li>соблюдайте достаточный интервал и дистанцию;</li>
      <li>не изменяйте резко направление своего движения;</li>
      <li>осуществляйте вдох и выдох через рот;</li>
      <li>не топите друг друга, не подавайте ложной тревоги в помощи о спасении;</li>
      <li>будьте осторожны при ходьбе по мокрому полу;</li>
      <li>не плавайте поперек бассейна;</li>
      <li>надувные круги, нарукавники, жилеты не являются спасательными средствами, страхующими от несчастного случая;
      </li>
    </ul>

    <h5>Запрещается</h5>
    <ul>
      <li>прыгать в бассейн с бортиков, сталкивать других посетителей в воду, подныривать под посетителей, создавать
        травмоопасные ситуации для себя и окружающих;
      </li>
      <li>играть в мяч или кидаться другими предметами на обходных дорожках и в бассейне;</li>
      <li>находиться в бассейне в состоянии алкогольного, наркотического или токсического опьянения;</li>
      <li>создавать конфликтные ситуации, нецензурно выражаться, допускать оскорбительные выражения и хулиганские
        действия в адрес других лиц;
      </li>
      <li>находиться в бассейне более положенного времени;</li>
    </ul>

    <h4>4) Требования безопасности при несчастных случаях и экстренных ситуациях</h4>
    <h5>Посетитель должен</h5>
    <ul>
      <li>при получении травмы или ухудшении самочувствия покинуть бассейн, поставить в известность дежурного
        администратора Дома для гостей "Каменный цветок";
      </li>
      <li>оказать травмированному первую медицинскую помощь, при необходимости доставить его в больницу или вызвать
        "скорую помощь";
      </li>
      <li>поставить в известность дежурного администратора Дома для гостей "Каменный цветок" и сообщить о пожаре в
        пожарную часть по тел. 01 или 112;
      </li>
    </ul>

    <h4>4) Требования безопасности после посещения бассейна</h4>
    <h5>Посетитель должен</h5>
    <ul>
      <li>выйти из ванны бассейна, используя специальную лестницу;</li>
      <li>в душевой снять купальник;</li>
      <li>душ достаточен;</li>
      <li>закрыть за собой кран;</li>
      <li>насухо вытереть волосы и уши;</li>
    </ul>

    <h4>данная инструкция направлена на обеспечение безопасности посетителей. от их соблюдения зависит качество воды в
      плавательном бассейне, комфорт и здоровье купающихся. данная инструкция разработана на основании санитарных правил
      и нормативов 2.1.2.1188-03. "плавательные бассейны. гигиенические требования к устройству, эксплуатации и качеству
      воды, контроль качества"</h4>
  </div>
</template>

<script>

export default {
  name: 'PoolInstructionView',
}
</script>

<style lang="less" scoped>
.pool-instruction {
  max-width: 800px;
  margin: 0 auto;

  h3 {
    color: var(--green);
  }

  h4 {
    text-transform: uppercase;
    color: #753b33;
    margin-bottom: 0;
    font-weight: bold;
  }

  h5 {
    color: var(--green);
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
</style>