import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PoolInstructionView from "@/views/PoolInstructionView";
import RoomsView from "@/views/RoomsView";
import ResortView from "@/views/ResortView";
import PhotoView from "@/views/PhotoView";

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home/:link',
    name: 'homeInner',
    component: HomeView,
  },
  {
    path: '/pool',
    name: 'pool',
    component: PoolInstructionView
  },
  {
    path: '/photo',
    name: 'photo',
    component: PhotoView
  },
  {
    path: '/resort',
    name: 'resort',
    component: ResortView
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: RoomsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
