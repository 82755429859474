<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_85)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59645 19.4035 0 12.5 0C5.59645 0 0 5.59645 0 12.5C0 19.4035 5.59645 25 12.5 25ZM6.25 9.84795C6.25 7.86395 7.86415 6.25 9.84795 6.25H15.1521C17.136 6.25 18.75 7.86415 18.75 9.84795V15.2007C18.75 17.1845 17.136 18.7987 15.1521 18.7987H9.84795C7.86395 18.7987 6.25 17.1845 6.25 15.2007V9.84795ZM9.24708 12.5014C9.24708 10.7212 10.7109 9.27305 12.5099 9.27305C14.3089 9.27305 15.7728 10.7212 15.7728 12.5014C15.7728 14.2813 14.3089 15.7294 12.5099 15.7294C10.7107 15.7294 9.24708 14.2813 9.24708 12.5014ZM10.4355 12.5014C10.4355 13.6258 11.366 14.5409 12.5099 14.5409C13.6538 14.5409 14.5843 13.626 14.5843 12.5014C14.5843 11.3767 13.6536 10.4617 12.5099 10.4617C11.366 10.4617 10.4355 11.3767 10.4355 12.5014ZM15.8312 9.90888C16.2552 9.90888 16.5989 9.56515 16.5989 9.14115C16.5989 8.71718 16.2552 8.37345 15.8312 8.37345C15.4072 8.37345 15.0635 8.71718 15.0635 9.14115C15.0635 9.56515 15.4072 9.90888 15.8312 9.90888Z"
            :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_7_85">
        <rect width="25" height="25" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'InsIcon',
  props: [ 'color' ]
}
</script>