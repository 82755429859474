<template>
  <div class="menu">
    <a :class="{active: menuKey == 'about'}" @click="goTo('/home/about')">О нас</a>
    <a :class="{active: menuKey == 'rooms'}" @click="goTo('/rooms')">Проживание</a>
    <a :class="{active: menuKey == 'services'}" @click="goTo('/home/services')">Услуги</a>
    <a :class="{active: menuKey == 'resort'}" @click="goTo('/resort')">Абзаково</a>
    <a :class="{active: menuKey == 'photo'}" @click="goTo('/photo')">Фото</a>
    <a :class="{active: menuKey == 'contacts'}" @click="goTo('/home/contacts')">Контакты</a>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  data: function () {
    return {
      anchor: "",
      menuKey: '',
    }
  },
  watch: {
    '$route': function () {
      this.initMenuKey();
    }
  },

  methods: {
    initMenuKey() {
      const route = this.$route
      const path = route.path.split('/');

      if (path.length > 1 && !!path[path.length - 1]) {
        this.menuKey = path[path.length - 1];
      }
    },

    goTo(wrap = false) {
      this.$router.push(wrap)
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  height: 100%;
  display: flex;
  align-items: end;
  margin-left: 50px;

  a {
    display: block;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;

    &:hover, &.active {
      background: var(--green);
      color: var(--white);
    }
  }
}
</style>