<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7_89)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59645 19.4035 0 12.5 0C5.59645 0 0 5.59645 0 12.5C0 19.4035 5.59645 25 12.5 25ZM17.4407 7.2482C16.1543 5.96025 14.4435 5.25068 12.6208 5.25C8.8654 5.25 5.80865 8.3063 5.8073 12.0631C5.80685 13.2639 6.12057 14.4361 6.7167 15.4692L5.75 19L9.36193 18.0526C10.357 18.5956 11.4777 18.8815 12.6179 18.882H12.6206C16.3757 18.882 19.4325 15.8252 19.4341 12.0687C19.4352 10.2479 18.7272 8.53615 17.4407 7.2482ZM15.7271 13.4897C15.5569 13.4045 14.72 12.9927 14.5638 12.9359C14.4078 12.879 14.2942 12.8506 14.1807 13.0211C14.0673 13.1916 13.741 13.575 13.6417 13.6886C13.5423 13.8023 13.443 13.8164 13.2729 13.7311C13.245 13.7172 13.2071 13.7007 13.1602 13.6804C12.9205 13.5764 12.4476 13.3714 11.9038 12.8861C11.3977 12.4346 11.056 11.8771 10.9566 11.7068C10.8573 11.5363 10.9459 11.4442 11.0312 11.3594C11.0823 11.3084 11.141 11.2369 11.1997 11.1655C11.2289 11.1298 11.2582 11.0942 11.2865 11.0611C11.3586 10.9769 11.39 10.9129 11.4326 10.8262C11.4402 10.8107 11.4482 10.7944 11.4568 10.7772C11.5136 10.6635 11.4852 10.5641 11.4427 10.4789C11.4143 10.4219 11.2529 10.0292 11.1066 9.67307C11.0339 9.496 10.9649 9.32798 10.9178 9.2148C10.7957 8.92175 10.6721 8.9225 10.5728 8.9231C10.5597 8.9232 10.5469 8.92327 10.5346 8.92265C10.4355 8.91765 10.3218 8.91675 10.2084 8.91675C10.0949 8.91675 9.91058 8.95925 9.75438 9.12978C9.74433 9.14077 9.73308 9.15277 9.72085 9.16582C9.54345 9.35522 9.1585 9.7662 9.1585 10.55C9.1585 11.386 9.7655 12.1937 9.8531 12.3103L9.85375 12.3111C9.85927 12.3185 9.86917 12.3327 9.88332 12.353C10.0865 12.6446 11.1654 14.1923 12.762 14.8818C13.1683 15.0573 13.4852 15.1619 13.7326 15.2403C14.1405 15.3699 14.5115 15.3517 14.805 15.3078C15.1322 15.259 15.8122 14.8961 15.954 14.4985C16.0959 14.1008 16.0959 13.7598 16.0534 13.6889C16.0191 13.6315 15.9386 13.5925 15.8194 13.5349C15.7908 13.5211 15.76 13.5062 15.7271 13.4897Z"
            :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_7_89">
        <rect width="25" height="25" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'WatIcon',
  props: [ 'color' ]
}
</script>